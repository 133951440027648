
const LEGACY_TIMECARD_EDITOR_STORAGE_KEY = 'LEGACY_TIMECARD_EDITOR_2024_12_15'

export default {
  name: 'manage-timecards',
  label: 'Time Cards',
  path: 'timecards',
  link: 'timecards',
  props: true,
  icon: 'table',
  description: 'View and edit worker time cards.',
  component: () => import('@/components/TabPanel.vue'),
  requirePerm: 'edit_punches',
  breadcrumbLeaf: true,
  children: [
    {
      name: 'timecard-editor',
      label: 'Edit',
      title: 'Edit Timecards',
      path: 'edit/:view?',
      component: () => import('./TimeCards.vue'),
      beforeEnter (to, from, next) {
        localStorage.removeItem(LEGACY_TIMECARD_EDITOR_STORAGE_KEY)
        next()
      }
    },
    {
      name: 'legacy-timecard-editor',
      label: 'Single Edit',
      title: 'Edit Timecards',
      path: 'legacy-edit/:view?',
      component: () => import('@/views/LegacyContainer.vue'),
      props: {
        legacyClassName: 'Fc.timecard.view.Index'
      },
      nav: false,
      beforeEnter (to, from, next) {
        localStorage.setItem(LEGACY_TIMECARD_EDITOR_STORAGE_KEY, 'true')
        next()
      }
    },
    {
      name: 'shiftcard-list',
      label: 'Bulk Edit',
      title: 'Bulk Edit Timecards',
      path: 'bulk-edit/:view?',
      requireGetter: 'scheduleEnabled',
      component: () => import('./ShiftCardList.vue')
    },
    {
      name: 'manage-timecards-index',
      path: '',
      redirect () {
        return { name: localStorage.getItem(LEGACY_TIMECARD_EDITOR_STORAGE_KEY) === 'true' ? 'legacy-timecard-editor' : 'timecard-editor' }
      }
    }
  ]
}
